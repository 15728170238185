import React, { createContext, useMemo } from "react";

export const LocaleContext = createContext({
  locale: "fi",
  pathname: ""
});

const LocaleContextProvider = ({ children, locale, pathname }) => {
  return (
    <LocaleContext.Provider value={{ locale: locale, pathname: pathname }}>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleContextProvider;
