import LocaleContextProvider from "./src/contexts/LocaleContext";
import { QueryParamProvider } from "use-query-params";
import { ReachAdapter } from "use-query-params/adapters/reach";
import posthog from "posthog-js";

const WrapQueryParams = ({ children }) => (
  <QueryParamProvider adapter={ReachAdapter}>{children}</QueryParamProvider>
);

const WrapPosthog = ({ children }) => {
  React.useEffect(() => {
    //add posthog to window for debugging
    window["posthog"] = posthog;
  }, []);

  React.useEffect(() => {
    const callback = () => {
      posthog.init("phc_kyyC6DY8Tkp2WEFMqw81mafZ71yz3LgaAcXNQLKXe2g", {
        api_host: "https://eu.posthog.com"
      });
      //eslint-disable-next-line
      if (Cookiebot.consent.statistics) {
        posthog.opt_in_capturing();
      }
      //eslint-disable-next-line
      if (!Cookiebot.constent.statistics) {
        posthog.opt_out_capturing();
      }
    };
    window.addEventListener("CookiebotOnAccept", callback, false);
    return () => {
      window.removeEventListener("CookiebotOnAccept", callback);
    };
  });
  return <>{children}</>;
};

export const WrapCommon = ({ children, props }) => {
  return (
    <WrapQueryParams>
      <LocaleContextProvider
        locale={props.pageContext.locale}
        pathname={props.location.pathname}
      >
        <WrapPosthog>{children}</WrapPosthog>
      </LocaleContextProvider>
    </WrapQueryParams>
  );
};
