import React from "react";
import posthog from "posthog-js";
import { WrapCommon } from "./WrapCommon";

export const wrapPageElement = ({
  element,
  props
}: {
  element: React.ReactNode;
  props: any;
}) => {
  return <WrapCommon props={props}>{element}</WrapCommon>;
};
